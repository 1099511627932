import Vue       from 'vue';
import VueRouter from 'vue-router';
import {locales} from '../locales';
import tags      from '../locales/seo';
import {store}   from '../main';
import i18n      from './i18n';

Vue.use(VueRouter);

const ContactsPage        = () => import('../views/ContactsPage');
const CompanyPage         = () => import('../views/CompanyPage');
const MainPage            = () => import('../views/MainPage');
const PackPage            = () => import('../views/PackPage');
const RentPage            = () => import('../views/RentPage');
const ReservationFormPage = () => import('../views/ReservationFormPage');
const ServicesPage        = () => import('../views/ServicesPage');
const PrivacyPage         = () => import('../views/PrivacyPage');
const ConditionsPage      = () => import('../views/ConditionsPage');
const NotFound            = () => import('../views/NotFound');

const router = new VueRouter({
	mode   : 'history',
	base   : process.env.BASE_URL,
	routes : [
		{
			path      : '/404',
			component : NotFound,
		},
		{
			path     : '',
			redirect : '/pt',
		},
		{
			path      : '/:lang(en|pt)?',
			component : {
				template : '<router-view></router-view>'
			},
			meta      : {
				gtm : 'Main Page',
			},
			beforeEnter(to, from, next) {
				const lang = to.params.lang;
				if (!['en', 'pt'].includes(lang)) return next('404');
				if (i18n.locale === lang) {
					store.dispatch('setLocalePath', { path : lang });
					return next();
				}

				i18n.locale = lang;
				i18n.setLocaleMessage(lang, locales[lang]);
				store.dispatch('setLocalePath', { path : lang });
				return next();
			},
			children  : [
				{
					path      : 'pre-reservation',
					name      : 'pre-reservation',
					component : ReservationFormPage,
					meta      : {
						gtm : 'Pre Reservation Page',
					},
				},
				{
					path      : 'rent',
					name      : 'rent',
					component : RentPage,
					meta      : {
						gtm : 'Rent Page',
					},
				},
				{
					path      : 'contacts',
					name      : 'contacts',
					component : ContactsPage,
					meta      : {
						gtm : 'Contacts Page',
					},
				}, {
					path      : 'services',
					name      : 'services',
					component : ServicesPage,
					meta      : {
						gtm : 'Services Page',
					},
				},
				{
					path      : 'company',
					name      : 'company',
					component : CompanyPage,
					meta      : {
						gtm : 'Company Page',
					},
				},
				{
					path      : 'politica-privacidade',
					name      : 'politica-privacidade',
					component : PrivacyPage
				},
				{
					path      : 'condicoes-gerais',
					name      : 'condicoes-gerais',
					component : ConditionsPage
				},
				{
					path      : '',
					name      : 'main',
					component : MainPage,
				},
				{
					path      : ':pack_name',
					name      : 'vehicles',
					component : PackPage,
					meta      : {
						gtm : 'Pack Page',
					},
				}
			],
		},
		{
			path     : '*',
			redirect : { name : 'notfound' },
		},
	]
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

	// Turn the meta tag definitions into actual elements in the head.
	let name = '';

	if (to.name === 'contacts' || to.name === 'services' || to.name === 'company' || to.name === 'rent') {
		name = to.name;
	} else {
		name = 'generic';
	}
	let lang = to.params.lang !== undefined ? to.params.lang : 'pt';
	tags[`seo_${lang}`][name].map(tagDef => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach(key => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach(tag => document.head.appendChild(tag));

	next();
});

export default router;
