import {CollapsePlugin} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Card             from 'bootstrap-vue';
import Button           from 'bootstrap-vue';
import Dropdown         from 'bootstrap-vue';
import 'es6-promise/auto';
import 'material-icons/iconfont/material-icons.css';
import 'sweetalert2/src/sweetalert2.scss';
import Vue              from 'vue';
import VueCarousel      from 'vue-carousel';
import VueGtm           from 'vue-gtm';
import moment           from 'vue-moment';
import Vue2Filters      from 'vue2-filters';
import {Vuelidate}      from 'vuelidate';
import Vuesax           from 'vuesax';

import 'vuesax/dist/vuesax.css';
import Vuex             from 'vuex';
import './assets/styles/scss/styles.scss';
import Footer           from './components/Footer';
import Header           from './components/Header';
import i18n             from './plugins/i18n';
import router           from './plugins/router';


Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(Card);
Vue.use(Button);
Vue.use(Vue2Filters);
Vue.use(VueCarousel);
Vue.use(Dropdown);
Vue.use(Vuelidate);
Vue.use(CollapsePlugin);
Vue.use(Vuesax);
Vue.use(moment);

Vue.use(VueGtm, {
	id           : 'GTM-MH7V9K4', // Your GTM ID
	enabled      : true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug        : true, // Whether or not display console logs debugs (optional)
	vueRouter    : router, // Pass the router instance to automatically sync with router (optional)
	ignoredViews : [] // If router, you can exclude some routes name (case insensitive) (optional)
});

export const store = new Vuex.Store({
	state     : {
		locale : 'en'
	},
	getters   : {
		setLocale : function (state) {
			return state.locale;
		}
	},
	mutations : {
		setLocalePath(state, object) {
			this.state.locale = object.path;
		},
	},
	actions   : {
		setLocalePath : function ({ commit }, object) {
			commit('setLocalePath', object);
		}
	}
});

new Vue({
	store,
	i18n,
	router,
	components : {
		'app-header' : Header,
		'app-footer' : Footer,
	}
}).$mount('#app');
