var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_vm._m(0),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light"},[_c('div',{staticClass:"container"},[(!_vm.showMenu)?_c('div',{staticStyle:{"display":"block","width":"25px"}}):_vm._e(),_c('div',{attrs:{"id":"parentx"}},[_c('vs-button',{staticClass:"button-menu d-block d-md-none",staticStyle:{"background":"rgba(0, 0, 0, 0) !important"},on:{"click":function($event){_vm.active = !_vm.active}}},[_c('vs-icon',{staticStyle:{"font-size":"1.5rem"},attrs:{"icon":"menu"}})],1),_c('vs-sidebar',{staticClass:"sidebarx",attrs:{"parent":"body","default-index":"1","color":"primary","spacer":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('vs-sidebar-item',[_c('router-link',{attrs:{"to":{ name: 'main', params: { lang: _vm.path } }}},[_vm._v(" "+_vm._s(_vm.$t("message.header.home"))+" ")])],1),_c('vs-sidebar-item',[_c('router-link',{attrs:{"to":{ name: 'rent', params: { lang: _vm.path } }}},[_vm._v(" "+_vm._s(_vm.$t("message.header.rent"))+" ")])],1),_c('vs-sidebar-item',[_c('a',{attrs:{"href":"https://usados.k2go.pt","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("message.header.used")))])]),_c('vs-sidebar-item',[_c('router-link',{attrs:{"to":{
								name: 'services',
								paras: { lang: _vm.path },
							}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.services"))+" ")])],1),_c('vs-sidebar-item',[_c('router-link',{attrs:{"to":{
								name: 'company',
								params: { lang: _vm.path },
							}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.company"))+" ")])],1),_c('vs-sidebar-item',[_c('router-link',{attrs:{"to":{
								name: 'contacts',
								params: { lang: _vm.path },
							}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.contacts"))+" ")])],1),_c('vs-sidebar-group',{attrs:{"title":_vm.$t('message.our_cars')}},_vm._l((_vm.json.packs),function(item,i){return _c('vs-sidebar-item',{key:i,attrs:{"index":i}},[_c('router-link',{attrs:{"to":{
									name: 'vehicles',
									params: {
										lang: _vm.path,
										pack_name: item.pack_route,
									},
								}},nativeOn:{"click":function($event){return _vm.scrollToTop.apply(null, arguments)}}},[_c('p',{staticClass:"pack-name"},[_vm._v(" "+_vm._s(_vm.$t("message.pack_name", { pack: _vm.$t( `message.pack[0].${item.pack_name}` ), }))+" ")]),_c('p',{staticClass:"car-title"},[_vm._v(" "+_vm._s(item.car_name)+" "),_c('span',{staticClass:"car-title2"},[_vm._v(_vm._s(_vm.$t("message.similar")))])])])],1)}),1),_c('b-dropdown',{attrs:{"id":"dropdown-2","text":_vm.lang}},[_c('b-dropdown-item',{attrs:{"to":"/pt"},on:{"click":function($event){return _vm.changeLanguage('pt')}}},[_c('span',{staticClass:"flag-icon flag-icon-pt"}),_vm._v(" PT ")]),_c('b-dropdown-item',{attrs:{"to":"/en"},on:{"click":function($event){return _vm.changeLanguage('en')}}},[_c('span',{staticClass:"flag-icon flag-icon-gb"}),_vm._v(" EN ")])],1)],1)],1),_c('div',{staticClass:"mx-auto d-block d-md-none"}),_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/images/k2go-logo.svg"),"alt":"logo K2GO Rent-a-Car","height":"65"}})]),_c('div',{staticClass:"mx-auto"}),_c('div',{staticClass:"d-none d-md-block"},[_c('ul',{staticClass:"nav"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{ name: 'main', params: { lang: _vm.path } }}},[_vm._v(" "+_vm._s(_vm.$t("message.header.home"))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{ name: 'rent', params: { lang: _vm.path } }}},[_vm._v(" "+_vm._s(_vm.$t("message.header.rent"))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"https://usados.k2go.pt","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("message.header.used")))])]),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
								name: 'services',
								params: { lang: _vm.path },
							}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.services"))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
								name: 'company',
								params: { lang: _vm.path },
							}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.company"))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
								name: 'contacts',
								params: { lang: _vm.path },
							}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.contacts"))+" ")])],1)])]),_c('div',{staticClass:"d-none d-md-block"},[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-1","text":_vm.lang}},[_c('b-dropdown-item',{attrs:{"to":"/pt"},on:{"click":function($event){return _vm.changeLanguage('pt')}}},[_c('span',{staticClass:"flag-icon flag-icon-pt"}),_vm._v(" PT ")]),_c('b-dropdown-item',{attrs:{"to":"/en"},on:{"click":function($event){return _vm.changeLanguage('en')}}},[_c('span',{staticClass:"flag-icon flag-icon-gb"}),_vm._v(" EN ")])],1)],1),_vm._m(1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar bg-dark"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"nav justify-content-end"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"tel:+351 918 925 882"}},[_c('i',{staticClass:"fas fa-phone mr-2"}),_vm._v("+351 918 925 882")])]),_c('li',{staticClass:"nav-item"},[_c('i',{staticClass:"fas fa-map-marker-alt mr-2"}),_vm._v(" RUA PAULO VI, 2410-146, LEIRIA ")]),_c('li',{staticClass:"nav-item"},[_c('i',{staticClass:"far fa-clock mr-2"}),_vm._v(" SEG - SEX 9h-18h | SÁB 9h-13h ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",attrs:{"href":"tel:+351 918 925 882"}},[_c('i',{staticClass:"fas fa-phone mr-2"}),_vm._v("+351 918 925 882")])
}]

export { render, staticRenderFns }