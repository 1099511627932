export default {
	'seo_pt' : {
		generic  : [
			{
				title : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		homepage : [
			{
				title : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		rent     : [
			{
				title : 'K2GO Rent-a-Car | Aluguer de viaturas'
			},
			{
				name    : 'description',
				content : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		services : [
			{
				title : 'K2GO Rent-a-Car | Serviços'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		company  : [
			{
				title : 'K2GO Rent-a-Car | A Empresa'
			},
			{
				name    : 'description',
				content : 'Conheça a K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO Rent-a-Car | A Empresa'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'Conheça a K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		contacts : [
			{
				title : 'K2GO Rent-a-Car | Contactos'
			},
			{
				name    : 'description',
				content : 'Contactos da K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO Rent-a-Car | Contactos'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'Contactos da K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		]
	},
	'seo_en' : {
		generic  : [
			{
				title : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		homepage : [
			{
				title : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		rent     : [
			{
				title : 'K2GO Rent-a-Car | Aluguer de viaturas'
			},
			{
				name    : 'description',
				content : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		services : [
			{
				title : 'K2GO Rent-a-Car | Serviços'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO - Rent-a-Car em Leiria e Fátima'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		company  : [
			{
				title : 'K2GO Rent-a-Car | A Empresa'
			},
			{
				name    : 'description',
				content : 'Conheça a K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO Rent-a-Car | A Empresa'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'Conheça a K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		contacts : [
			{
				title : 'K2GO Rent-a-Car | Contactos'
			},
			{
				name    : 'description',
				content : 'Contactos da K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'K2GO Rent-a-Car | Contactos'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://k2go.pt'
			},
			{
				property : 'og:description',
				content  : 'Contactos da K2GO Rent-a-Car. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'K2GO.pt Rent-a-Car'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/k2go/k2go-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		]
	}
};
