import Vue       from 'vue';
import VueI18n   from 'vue-i18n';
import {locales} from '../locales';

Vue.use(VueI18n);

function loadLocaleMessages() {
	const messages = {};
	for (let locale in locales) {
		messages[locale] = locales[locale];
	}
	return messages;
}

export default new VueI18n({
	locale         : process.env.VUE_APP_I18N_LOCALE || navigator.language.split('-')[0],
	fallbackLocale : process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages       : loadLocaleMessages()
});
