<template>
    <footer class="footer">
        <div class="container pb-5">
            <div class="row">
                <div class="col-md-3 col-xs-12 pt-5">
                    <h4>Menu</h4>
                    <ul class="footer-menu">
                        <li>
                            <i class="fas fa-home"></i>
                            <router-link :to="{name:'main', params : {lang:path}}" @click.native="scrolltoTop()">
                                {{ $t('message.header.home') }}
                            </router-link>
                        </li>
                        <li>
                            <i class="fas fa-car"></i>
                            <router-link :to="{name:'rent', params : {lang:path}}" @click.native="scrolltoTop()">
                                {{ $t('message.header.rent') }}
                            </router-link>
                        </li>
                        <li>
                            <i class="fas fa-car-alt"></i>
                            <a href="https://usados.k2go.pt" target="_blank">{{ $t('message.header.used') }}</a>
                        </li> 
                        <li>
                            <i class="fas fa-tools"></i>
                            <router-link :to="{name:'services', params : {lang:path}}" @click.native="scrolltoTop()">
                                {{ $t('message.header.services') }}
                            </router-link>
                        </li>
                        <li>
                            <i class="fas fa-building"></i>
                             <router-link :to="{name:'company', params : {lang:path}}" @click.native="scrolltoTop()">
                                {{ $t('message.header.company') }}
                            </router-link>
                        </li>
                        <li>
                            <i class="fas fa-phone-alt"></i>
                            <router-link :to="{name:'contacts', params : {lang:path}}" @click.native="scrolltoTop()">
                                {{ $t('message.header.contacts') }}
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4 col-xs-12 pt-5">
                    <h4>{{ $t('message.footer.contacts') }}</h4>
                        <div class="footer-contactos-icon">
                            <i class="fas fa-home"></i>
                        </div>
                        <div class="footer-contactos-text">
                            <h5>{{ $t('message.footer.address') }}</h5>
                            <p>Rua Paulo VI, 2410-146, Leiria</p>
                        </div>
                        <div class="footer-contactos-icon">
                            <i class="far fa-envelope"></i>
                        </div>
                        <div class="footer-contactos-text">
                            <h5>Email</h5>
                            <p>reservas.k2go@gmail.com</p>
                        </div>
                </div>
                <div class="col-md-5 col-xs-12 pt-5">
                    <h4>{{ $t('message.footer.schedule') }}</h4>
                    <div class="footer-contactos-icon">
                        <i class="far fa-clock fa-lg iconColor"></i>
                    </div>
                    <div class="footer-contactos-text">
                            <p class="mb-0">{{ $t('message.footer.dias-uteis') }} - 10:00 - 13:00 | 14:30 - 19:30</p>
                            <p class="mb-0">{{ $t('message.footer.saturday') }} - 10:00 - 13:00</p>                   
                    </div>
                    <img class="mt-4" src="../assets/images/k2go-logo.svg" height="60px"/>
                </div>
            </div>
        </div>
        <div class="container-fluid sub-footer">
            <cookie-law theme="mytheme">
                <div slot-scope="props" style="margin: 0 auto; padding: 15px 15px 0 15px;">
                    <p style="line-height: 2rem;">
                        {{ $t('message.cookies.text') }} <a style="text-decoration: underline;"
                                                            v-bind:href="'/' + lang + '/termos-condicoes'" target="_blank">{{
                        $t('message.form.terms') }}</a>
                        <button class="ml-3 btn btn-primary" @click="props.accept"
                                style="font-size: 14px;font-weight: 700;">
                            <span>{{ $t('message.cookies.accept') }}</span>
                        </button>
                    </p>
                </div>
            </cookie-law>
            <div class="container">
                <div class="row">
                    <div class="col-12 d-md-flex justify-content-md-between">
                        <div class="d-md-flex justify-content-md-around">
                            <div>
                                &copy; {{ year }} <span class="footer-yellow"><a target="_blank" href="https://k2go.pt">K2GO Rent-a-Car</a></span>
                            </div>
                            <div class="pl-1 pr-1 d-none d-md-block">
                                |
                            </div>
                            <div>
                                {{ $t('message.tax') }}
                            </div>
                            <div class="pl-1 pr-1 d-none d-md-block">
                                |
                            </div>
                            <div>
                                <a v-bind:href="'/' + lang + '/politica-privacidade'">{{ $t('message.form.privacy_link') }}</a>
                            </div>
                        </div>
                        <div class="pt-3 pt-md-0">
                            <a href="https://agency.rotauto.com/" target="_blank">{{ $t('message.developed') }}</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </footer>
</template>

<script>
    import * as moment from 'moment';
    import CookieLaw   from 'vue-cookie-law';
    import {version}   from '../../package.json';

    export default {
        name       : 'Footer',
        components : { CookieLaw },
        props      : {},
        data       : function () {
            return {
                lang    : this.$store.getters.setLocale,
                year    : moment().year(),
                version : version
            };
        },
        computed   : {
            path() {
                return this.$store.getters.setLocale;
            }
        },
        methods    : {
            scrolltoTop(){
                window.scrollTo(0,0)
            }
        }
    };
</script>
