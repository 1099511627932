<template>
	<header>
		<nav class="navbar bg-dark">
			<div class="container">
				<ul class="nav justify-content-end">
					<li class="nav-item">
						<a class="nav-link" href="tel:+351 918 925 882"
							><i class="fas fa-phone mr-2"></i>+351 918 925
							882</a
						>
					</li>
					<li class="nav-item">
						<i class="fas fa-map-marker-alt mr-2"></i> RUA PAULO VI,
						2410-146, LEIRIA
					</li>
					<li class="nav-item">
						<i class="far fa-clock mr-2"></i> SEG - SEX 9h-18h | SÁB
						9h-13h
					</li>
				</ul>
			</div>
		</nav>
		<nav class="navbar navbar-expand-lg navbar-light bg-light">
			<div class="container">
				<div style="display: block; width: 25px" v-if="!showMenu"></div>
				<div id="parentx">
					<vs-button
						@click="active = !active"
						class="button-menu d-block d-md-none"
						style="background: rgba(0, 0, 0, 0) !important"
					>
						<vs-icon
							icon="menu"
							style="font-size: 1.5rem"
						></vs-icon>
					</vs-button>
					<vs-sidebar
						parent="body"
						default-index="1"
						color="primary"
						class="sidebarx"
						spacer
						v-model="active"
					>
						<vs-sidebar-item>
							<router-link
								:to="{ name: 'main', params: { lang: path } }"
							>
								{{ $t("message.header.home") }}
							</router-link>
						</vs-sidebar-item>
						<vs-sidebar-item>
							<router-link
								:to="{ name: 'rent', params: { lang: path } }"
							>
								{{ $t("message.header.rent") }}
							</router-link>
						</vs-sidebar-item>
						<vs-sidebar-item>
							<a href="https://usados.k2go.pt" target="_blank">{{
								$t("message.header.used")
							}}</a>
						</vs-sidebar-item>
						<vs-sidebar-item>
							<router-link
								:to="{
									name: 'services',
									paras: { lang: path },
								}"
							>
								{{ $t("message.header.services") }}
							</router-link>
						</vs-sidebar-item>
						<vs-sidebar-item>
							<router-link
								:to="{
									name: 'company',
									params: { lang: path },
								}"
							>
								{{ $t("message.header.company") }}
							</router-link>
						</vs-sidebar-item>
						<vs-sidebar-item>
							<router-link
								:to="{
									name: 'contacts',
									params: { lang: path },
								}"
							>
								{{ $t("message.header.contacts") }}
							</router-link>
						</vs-sidebar-item>
						<vs-sidebar-group :title="$t('message.our_cars')">
							<vs-sidebar-item
								v-for="(item, i) of json.packs"
								:key="i"
								:index="i"
							>
								<router-link
									:to="{
										name: 'vehicles',
										params: {
											lang: path,
											pack_name: item.pack_route,
										},
									}"
									@click.native="scrollToTop"
								>
									<p class="pack-name">
										{{
											$t("message.pack_name", {
												pack: $t(
													`message.pack[0].${item.pack_name}`
												),
											})
										}}
									</p>
									<p class="car-title">
										{{ item.car_name }}
										<span class="car-title2">{{
											$t("message.similar")
										}}</span>
									</p>
								</router-link>
							</vs-sidebar-item>
						</vs-sidebar-group>
						<b-dropdown id="dropdown-2" v-bind:text="lang">
							<b-dropdown-item
								to="/pt"
								@click="changeLanguage('pt')"
							>
								<span class="flag-icon flag-icon-pt"></span> PT
							</b-dropdown-item>
							<b-dropdown-item
								to="/en"
								@click="changeLanguage('en')"
							>
								<span class="flag-icon flag-icon-gb"></span> EN
							</b-dropdown-item>
						</b-dropdown>
					</vs-sidebar>
				</div>
				<div class="mx-auto d-block d-md-none"></div>
				<router-link to="/" class="navbar-brand">
					<img
						src="../assets/images/k2go-logo.svg"
						alt="logo K2GO Rent-a-Car"
						height="65"
					/>
				</router-link>
				<div class="mx-auto"></div>
				<div class="d-none d-md-block">
					<ul class="nav">
						<li class="nav-item">
							<router-link
								class="nav-link active"
								:to="{ name: 'main', params: { lang: path } }"
							>
								{{ $t("message.header.home") }}
							</router-link>
						</li>
						<li class="nav-item">
							<router-link
								class="nav-link active"
								:to="{ name: 'rent', params: { lang: path } }"
							>
								{{ $t("message.header.rent") }}
							</router-link>
						</li>
						<li class="nav-item">
							<a
								class="nav-link active"
								href="https://usados.k2go.pt"
								target="_blank"
								>{{ $t("message.header.used") }}</a
							>
						</li>
						<li class="nav-item">
							<router-link
								class="nav-link active"
								:to="{
									name: 'services',
									params: { lang: path },
								}"
							>
								{{ $t("message.header.services") }}
							</router-link>
						</li>
						<li class="nav-item">
							<router-link
								class="nav-link active"
								:to="{
									name: 'company',
									params: { lang: path },
								}"
							>
								{{ $t("message.header.company") }}
							</router-link>
						</li>
						<li class="nav-item">
							<router-link
								class="nav-link active"
								:to="{
									name: 'contacts',
									params: { lang: path },
								}"
							>
								{{ $t("message.header.contacts") }}
							</router-link>
						</li>
					</ul>
				</div>
				<div class="d-none d-md-block">
					<b-dropdown
						id="dropdown-1"
						v-bind:text="lang"
						class="m-md-2"
					>
						<b-dropdown-item to="/pt" @click="changeLanguage('pt')">
							<span class="flag-icon flag-icon-pt"></span> PT
						</b-dropdown-item>
						<b-dropdown-item to="/en" @click="changeLanguage('en')">
							<span class="flag-icon flag-icon-gb"></span> EN
						</b-dropdown-item>
					</b-dropdown>
				</div>
				<a class="nav-link" href="tel:+351 918 925 882"
					><i class="fas fa-phone mr-2"></i>+351 918 925 882</a
				>
			</div>
		</nav>
	</header>
</template>

<script>
import json from "../../website-data.json";
import { locales } from "../locales";
import i18n from "../plugins/i18n";

export default {
	name: "Header",
	components: {},
	props: {},
	data: function () {
		return {
			lang: this.$store.getters.setLocale,
			json: json,
			showMenu: true,
			drop: false,
			active: false,
		};
	},
	computed: {
		path() {
			return this.$store.getters.setLocale;
		},
	},
	methods: {
		openDrop() {
			this.drop = true;
		},
		scrollToTop() {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
		changeLanguage(e) {
			const lang = e;
			const to = this.$router.resolve({ params: { lang } });
			i18n.locale = lang;
			this.lang = lang;
			i18n.setLocaleMessage(lang, locales[lang]);
			this.$router.push(to.location).catch((err) => {});
			this.$store.dispatch("setLocalePath", { path: e });
		},
	},
};
</script>
